<template>
  <div class="outer">
    <div class="sub-banner">
      <div class="bgImg">
        <div class="sub-txt">
          <h6>{{ subMenu.tips }}</h6>
          <p>{{ subMenu.name }}</p>
        </div>
      </div>
    </div>
    <div class="second-nav">
      <div class="rel warpper">
        <div class="nav-list">
          <ul
            :style="{
              'justify-content':
                subMenu.childList && subMenu.childList.length > 7
                  ? ''
                  : 'center',
            }"
          >
            <template v-if="subMenu.childList">
              <li
                v-for="(item, index) in subMenu.childList"
                :key="index"
                :style="{ left: navLeft + 'px' }"
				 v-if="item.name != '大数据'"
              >
                <a
                  :class="[currentRoute === item.router ? 'a-actived' : '']"
                  @click="() => goThere(item.router)"
                  >{{ item.name }}</a
                >
              </li>
            </template>
          </ul>
        </div>
        <div
          class="button-next-left"
          :class="
            flag == 1 ? 'button-next-left-bgImg01' : 'button-next-left-bgImg02'
          "
          v-if="subMenu.childList && subMenu.childList.length > 7"
          @click="point_left"
          :style="{
            cursor: cursorLfet == 1 ? '' : 'pointer',
          }"
        ></div>
        <div
          class="button-next-right"
          :class="
            flag == 0
              ? 'button-next-right-bgImg01'
              : 'button-next-right-bgImg02'
          "
          v-if="subMenu.childList && subMenu.childList.length > 7"
          @click="point_right"
          :style="{
            cursor: cursorRight == 1 ? '' : 'pointer',
          }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      flag: 1,
      navLeft: 0,
      cursorLfet: 1,
      cursorRight: 0,
    };
  },
  computed: {
    subMenu() {
      return this.$store.state.permission.subMenu || {};
    },
    currentRoute() {
      return this.$store.state.permission.currentRoute;
    },
  },
  methods: {
    point_left() {
      this.cursorRight = 0;
      this.flag = 1;
      if (this.navLeft >= 0) {
        this.cursorLfet = 1;
      } else {
        this.navLeft += 157;
      }
    },
    point_right() {
      this.cursorLfet = 0;
      this.flag = 0;
      if (this.navLeft <= this.subMenu.childList.length * -157 + 1100) {
        this.cursorRight = 1;
      } else {
        this.navLeft += -157;
      }
    },

    goThere(router) {
      // if (this.currentRoute === router) return;
      if (router == 'honorQualification') {
        this.$store.commit('setHonorShowView', false)
      }
      this.$router.push({ name: router }, () => {}, () => {});
    },
  },
  watch: {
    subMenu(n) {
      this.flag = 1
      this.navLeft = 0
      this.cursorLfet = 1
      this.cursorRight = 0
    }
  }
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>