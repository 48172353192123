<template>
  <div class="sub-main">
    <header-bar></header-bar>
    <sub-banner></sub-banner>
    <router-view></router-view>
    <footer-component></footer-component>
  </div>
</template>

<script>
import headerBar from "@components/headerBar";
import footerComponent from "@components/footer";
import subBanner from "@components/subBanner";
export default {
  name: "",
  components: {
    headerBar,
    footerComponent,
    subBanner,
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.sub-main {
  width: 100%;
  height: 100%;
}
</style>